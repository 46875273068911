<template>
  <layoutContainer>
    <el-tabs type="border-card" tabPosition="left" v-if="UserInfo.isManage" @tab-click="tabClick">
      <el-tab-pane :label="`客户需求(${needList1.filter(item => !item.isDealed).length})`">
        <need :needList="needList1" type="1" @refreshNeedList="getProjectIntentionList"></need>
      </el-tab-pane>
      <el-tab-pane :label="`用户引荐(${needList2.filter(item => !item.isDealed).length})`">
        <need :needList="needList2" type="2" @refreshNeedList="getProjectIntentionList"></need>
      </el-tab-pane>
      <el-tab-pane :label="`伙伴引荐(${needList3.filter(item => !item.isDealed).length})`">
        <need :needList="needList3" type="3" @refreshNeedList="getProjectIntentionList"></need>
      </el-tab-pane>
      <el-tab-pane :label="`公司入驻(${companyPendingTotal})`" name="company">
        <enter :needList="companyEnterList" type="1" @refreshNeedList="companyUncheckedList"></enter>
      </el-tab-pane>
      <el-tab-pane :label="`团队入驻(${teamPendingTotal})`" name="team">
        <enter :needList="teamEnterList" type="2" @refreshNeedList="teamUncheckedList"></enter>
      </el-tab-pane>
      <el-tab-pane :label="`个人入驻(${employPendingTotal})`" name="employ">
        <enter :needList="employeeEnterList" type="3" @refreshNeedList="employeeUncheckedList"></enter>
      </el-tab-pane>
      <el-tab-pane label="编辑标准">
        <edit type="1"></edit>
      </el-tab-pane>
      <el-tab-pane label="编辑产品">
        <edit type="2"></edit>
      </el-tab-pane>
      <el-tab-pane :label="`退款申请(${vipPendingTotal})`" name="vip">
        <el-select v-model="productBusinessType" placeholder="请选择">
          <el-option v-for="item in productBusinessList" :key="item.name" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
        <order :needList="vipList" :type="1" @refreshNeedList="getVip"></order>
      </el-tab-pane>
      <el-tab-pane :label="`发票申请(${invoicePendingTotal})`" name="invoice">
        <el-select v-model="productBusinessType" placeholder="请选择">
          <el-option v-for="item in productBusinessList" :key="item.name" :label="item.name" :value="item.value">
          </el-option>
        </el-select>
        <order :needList="invoiceList" :type="2" @refreshNeedList="getInvoice"></order>
      </el-tab-pane>
      <el-tab-pane :label="`首页轮播图`">
        <banner
          :bannerList="bannerList"
          :bannerInfoList="bannerInfoList"
          @refreshbannerList="getProjectIntentionList"
          @refreshbannerInfoList="getProjectIntentionList"
        ></banner>
      </el-tab-pane>
      <el-tab-pane :label="`知识操作(${knowledgeNoTotal})`" name="knowledge">
        <!-- <enter :needList="knowledgeList" type="3" @refreshNeedList="employeeUncheckedList"></enter> -->
        <div class="search">
          <el-form label-width="80px">
            <el-form-item label="状态">
              <el-select v-model="knowledgeStatus" placeholder="状态:">
                <el-option label="全部" value="-1"></el-option>
                <el-option label="待审核" value="1"></el-option>
                <el-option label="已通过" value="2"></el-option>
                <el-option label="已拒绝" value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
          <el-form label-width="80px">
            <el-form-item label="搜索">
              <el-select v-model="queryType" placeholder="搜索" style="margin-right: 10px;">
                <el-option label="全部" value="-1"></el-option>
                <el-option label="发布者ID" value="1"></el-option>
                <el-option label="产品名称" value="2"></el-option>
              </el-select>
              <el-input style="width: 200px;" v-model="queryContent"></el-input>
            </el-form-item>
          </el-form>
        </div>
        <knowledgeAction :data="knowledgeList" @getData="getKnowledgeCourseList"></knowledgeAction>
      </el-tab-pane>
    </el-tabs>
    <div v-else>您暂无权限查看此页面!</div>
    <el-pagination
      class="pagination"
      v-if="UserInfo.isManage && ['company', 'team', 'employ', 'vip', 'invoice', 'knowledge'].includes(this.tabName)"
      background
      layout="prev, pager, next"
      :total="total"
      :current-page="pageNum"
      :page-size="pageSize"
      @current-change="currentPage"
    >
    </el-pagination>
  </layoutContainer>
</template>

<script>
import edit from './edit'
import enter from './enter'
import order from './order'
import need from './need'
import banner from './banner'
import knowledgeAction from './knowledgeAction.vue'

import {
  getProjectIntentionListApi,
  companyUncheckedList,
  employeeUncheckedList,
  teamUncheckedList,
  getProductBusiness,
  getInvoiceList,
  getCourseApplyList,
  getInvoiceBusinessApi,
  getVipList
} from '../../api/background'
import {mapState} from 'vuex'
export default {
  components: {
    edit,
    enter,
    need,
    order,
    knowledgeAction,
    banner
  },
  computed: {
    ...mapState(['UserInfo'])
  },
  watch: {
    productBusinessType: {
      immediate: true,
      handler() {
        this.pageNum = 1
        this.getVip()
        this.getInvoice()
      }
    },
    knowledgeStatus() {
      this.getKnowledgeCourseList(1)
    },
    queryType() {
      this.getKnowledgeCourseList(1)
    }
  },
  data() {
    return {
      needList1: [],
      needList2: [],
      needList3: [],
      queryContent: '',
      vipList: [],
      invoiceList: [],
      productBusinessList: [],
      productBusinessType: null,
      companyEnterList: [],
      employeeEnterList: [],
      teamEnterList: [],
      knowledgeList: [],
      total: 0,
      tabName: '',
      pageNum: 1,
      pageSize: 10,
      knowledgeNoTotal: 0,
      knowledgeStatus: '-1',
      queryType: '-1',
      teamPendingTotal: 0,
      companyPendingTotal: 0,
      employPendingTotal: 0,
      vipPendingTotal: 0,
      invoicePendingTotal: 0,
      bannerList: [{}],
      bannerInfoList: [{}]
    }
  },
  created() {
    this.getProjectIntentionList()
    this.companyUncheckedList(1)
    this.employeeUncheckedList(1)
    this.teamUncheckedList(1)
    this.getVip(1)
    this.getKnowledgeList()
    this.getKnowledgeCourseList()
    this.getInvoice(1)
  },
  methods: {
    currentPage(page) {
      this.pageNum = page
      if (this.tabName === 'company') this.companyUncheckedList(page)
      if (this.tabName === 'team') this.teamUncheckedList(page)
      if (this.tabName === 'employ') this.employeeUncheckedList(page)
      if (this.tabName === 'vip') this.getVip(page)
      if (this.tabName === 'invoice') this.getInvoice(page)
      if (this.tabName === 'knowledge') this.getKnowledgeCourseList(page)
    },
    tabClick(tab) {
      this.pageNum = 1
      this.tabName = tab.name
      this.productBusinessType = null
      if (tab.name === 'company') this.companyUncheckedList(1)
      if (tab.name === 'team') this.teamUncheckedList(1)
      if (tab.name === 'employ') this.employeeUncheckedList(1)
      if (tab.name === 'knowledge') this.getKnowledgeCourseList(1)
      if (this.tabName === 'vip') {
        this.getProduct()
        this.getVip(1)
      }
      if (this.tabName === 'invoice') {
        this.getInvoiceBusiness()
        this.getInvoice(1)
      }
    },
    getProduct() {
      this.productBusinessList = []
      getProductBusiness().then(res => {
        this.productBusinessList = [
          {name: '全部', value: null},
          ...res.data.map(item => {
            return {
              name: item.name,
              value: item.name
            }
          })
        ]
      })
    },
    getInvoiceBusiness() {
      this.productBusinessList = []
      getInvoiceBusinessApi().then(res => {
        this.productBusinessList = [
          {name: '全部', value: null},
          ...res.data.map(item => {
            return {
              name: item.name,
              value: item.name
            }
          })
        ]
      })
    },
    getProjectIntentionList() {
      getProjectIntentionListApi()
        .then(res => {
          this.needList1 = res.data.filter(item => item.isSelfSubmit).reverse()
          this.needList2 = res.data.filter(item => item.isUserRecommend).reverse()
          this.needList3 = res.data.filter(item => item.isPartnerRecommend).reverse()
        })
        .catch(err => {
          console.log('%c 后台需求 err....', 'color: red; font-style: italic', JSON.stringify(err, null, 2))
        })
    },

    getInvoice(page) {
      this.pageNum = page || this.pageNum
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        productBusiness: this.productBusinessType
      }
      getInvoiceList(params).then(res => {
        this.invoiceList = res.data.records
        this.total = res.data.total
        this.invoicePendingTotal = res.data.pendingTotal
      })
    },
    getVip(page) {
      this.pageNum = page || this.pageNum
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        productBusiness: this.productBusinessType
      }
      getVipList(params).then(res => {
        this.vipList = res.data.records
        this.total = res.data.total
        this.vipPendingTotal = res.data.pendingTotal
      })
    },

    companyUncheckedList(page) {
      this.pageNum = page || this.pageNum
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      companyUncheckedList(params).then(res => {
        this.companyEnterList = res.data.records
        this.total = res.data.total
        this.companyPendingTotal = res.data.pendingTotal
      })
    },
    employeeUncheckedList(page) {
      this.pageNum = page || this.pageNum
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      employeeUncheckedList(params).then(res => {
        this.employeeEnterList = res.data.records
        this.total = res.data.total
        this.employPendingTotal = res.data.pendingTotal
      })
    },
    getKnowledgeList() {
      const params = {
        pageNum: 10000,
        status: 1,
        pageSize: this.pageSize,
        queryType: -1
      }
      getCourseApplyList(params).then(res => {
        this.knowledgeNoTotal = res.data.total
      })
    },
    getKnowledgeCourseList(page) {
      this.pageNum = page || this.pageNum
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        queryContent: this.queryContent,
        queryType: this.queryType,
        status: this.knowledgeStatus
      }
      getCourseApplyList(params).then(res => {
        this.total = res.data.total
        this.knowledgeList = res.data.records
      })
    },
    teamUncheckedList(page) {
      this.pageNum = page || this.pageNum
      const params = {
        pageNum: this.pageNum,
        pageSize: this.pageSize
      }
      teamUncheckedList(params).then(res => {
        this.teamEnterList = res.data.records
        this.total = res.data.total
        this.teamPendingTotal = res.data.pendingTotal
      })
    }
  }
}
</script>

<style scoped>
.pagination {
  display: flex;
  justify-content: flex-end;
}
.search {
  display: flex;
  justify-content: space-between;
}
</style>
